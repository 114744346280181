import { useIntl } from "react-intl";

import WisepopsIcon from "@/Components/Icons/Brands/WisepopsIcon.svg?react";

import {
    Container,
    Title,
    Description,
} from "./Components/MaintenanceComponents";

const Maintenance = (): JSX.Element => {
    const intl = useIntl();

    return (
        <Container>
            <WisepopsIcon />
            <Title>
                {intl.formatMessage({
                    id: "maintenance.title",
                    defaultMessage: "This website is under maintenance",
                    description: "Maintenance page title",
                })}
            </Title>
            <Description>
                {intl.formatMessage({
                    id: "maintenance.description",
                    defaultMessage: "🔧 We're preparing to serve you better.",
                    description: "Maintenance page description",
                })}
            </Description>
        </Container>
    );
};

export default Maintenance;
